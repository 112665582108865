import * as React from 'react'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
const axios = require('axios')

import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme
} from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { Grid } from 'semantic-ui-react'

// import * as nodemailer from 'nodemailer'

export interface EmailInput {
  email: string | null
  firstName: string | null
  lastName: string | null
  message: string | null
  phoneNumber: string | null
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  })
)(InputBase)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
      // flexWrap: 'nowrwap'
    },
    margin: {
      margin: theme.spacing(1)
    },
    input: {
      fontFamily: 'Oswald'
    },
    width: {
      width: 'auto'
    },
    button: {
      backgroundColor: '#415A77',
      '&:hover': {
        backgroundColor: '#58b5bc'
      },
      fontFamily: 'Oswald'
    }
  })
)

export default function FormDialog(props: any) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  function sendEmail(
    email: string,
    firstName: string,
    lastName: string,
    message: string,
    phoneNumber: string
  ) {
    axios
      // .post('http://localhost:8000http://localhost:5000/api/email', {
      .post(
        `https://cors-anywhere.herokuapp.com/https://clarks-construction-backend.herokuapp.com/api/email`,
        {
          email,
          firstName,
          lastName,
          message,
          phoneNumber
        }
      )
      .then((res: any) => console.log('res', res))
      .catch((error: any) => console.log('error', error))
  }

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const useSignUpForm = () => {
    const [inputs, setInputs] = React.useState<EmailInput>({})
    const handleSubmit = (event: any) => {
      if (event) {
        event.preventDefault()
        alert(
          `Successfully Sent Quote. We Will Reach Out With An Estimate Soon. Thank You!`
        )
        // send the email
        sendEmail(
          inputs.email || `Email was not provided`,
          inputs.firstName || `First Name was not provided`,
          inputs.lastName || `Last Name was not provided`,
          inputs.message || `Message was not provided`,
          inputs.phoneNumber || `Phone Number was not provided`
        )
        handleClose()
      }
      // callback()
    }
    const handleInputChange = (event: any) => {
      event.persist()
      setInputs((inputs: any) => ({
        ...inputs,
        [event.target.name]: event.target.value
      }))
    }
    return {
      handleSubmit,
      handleInputChange,
      inputs
    }
  }

  const { inputs, handleInputChange, handleSubmit } = useSignUpForm()

  return (
    <div>
      <Button
        // disableFocusRipple={false}
        // disableRipple={false}
        // disableTouchRipple={false}
        color={'primary'}
        variant={'contained'}
        onClick={handleClickOpen}
        size="large"
        className={classes.button}
      >
        Request Free Quote
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.input} id="form-dialog-title">
          Request Free Quote
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.input}>
            Please send us your contact information. In order for us to give you
            the best estimate please include as much detail as possible.
          </DialogContentText>
          <form onSubmit={handleSubmit} className={classes.root}>
            <Grid stackable textAlign="center">
              <Grid.Row>
                <Grid.Column>
                  <FormControl className={classes.margin}>
                    <InputLabel
                      className={classes.input}
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      First Name
                    </InputLabel>
                    <BootstrapInput
                      onChange={handleInputChange}
                      id="bootstrap-input"
                      name="firstName"
                      value={inputs.firstName}
                    />
                  </FormControl>
                  <FormControl className={classes.margin}>
                    <InputLabel
                      className={classes.input}
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Last Name
                    </InputLabel>
                    <BootstrapInput
                      onChange={handleInputChange}
                      id="bootstrap-input"
                      name="lastName"
                      value={inputs.lastName}
                    />
                  </FormControl>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <FormControl className={classes.margin}>
                    <InputLabel
                      className={classes.input}
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Phone Number
                    </InputLabel>
                    <BootstrapInput
                      onChange={handleInputChange}
                      id="bootstrap-input"
                      name="phoneNumber"
                      value={inputs.phoneNumber}
                    />
                  </FormControl>
                  <FormControl className={classes.margin}>
                    <InputLabel
                      className={classes.input}
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Email
                    </InputLabel>
                    <BootstrapInput
                      onChange={handleInputChange}
                      id="bootstrap-input"
                      name="email"
                      value={inputs.email}
                    />
                  </FormControl>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <FormControl className={classes.margin}>
                    <InputLabel
                      className={classes.input}
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Message
                    </InputLabel>
                    <BootstrapInput
                      onChange={handleInputChange}
                      fullWidth
                      id="bootstrap-input"
                      name="message"
                      value={inputs.message}
                      rows="4"
                    />
                  </FormControl>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
